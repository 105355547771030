<template>
  <div class="box">
    <div class="user_info">
      <div class="title">
        <el-button type="text" @click="changeUserInfo">{{
          changeFlag ? "保存" : "编辑"
        }}</el-button>
      </div>
      <div>
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          action
          :http-request="Upload"
          :on-success="handleSuccess"
          :on-error="handfileErr"
          :before-upload="beforeAvatarUpload"
          v-model="imageUrl"
          v-if="changeFlag"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-image
          v-else
          style="width: 100px; height: 100px"
          :src="userInfo.avatar"
        ></el-image>
      </div>
      <div>
        <el-input
          v-if="changeFlag"
          v-model="input"
          placeholder="请输入内容"
          style="width: 200px"
        ></el-input>
        <span style="font-size: 20px" v-else>{{ userInfo.nickname }}</span>
        <img
          v-if="userInfo.vip && !changeFlag"
          src="../../assets/vip_1.svg"
          alt=""
          style="width: 20px; height: 20px"
        />
        <img
          v-if="!userInfo.vip && !changeFlag"
          src="../../assets/vip_0.svg"
          alt=""
          style="width: 20px; height: 20px; color: #fff"
        />
      </div>
      <div v-if="userInfo.vip_expired_at && userInfo.vip_expired_at < $moment().valueOf()  && !changeFlag">
        vip到期时间:{{ $moment(userInfo.vip_expired_at * 1000).format('YYYY-MM-DD') }}
      </div>
      <div>
        持有金币:{{ userInfo.vip_expired_at && !changeFlag?userInfo.gold:"0" }}
      </div>
      <div>
        {{ userInfo.phone }}
      </div>
    </div>
    <div class="shiming">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="width: 400px"
        label-position="top"
        :rules="rules"
      >
        <h1 style="text-align: center; padding: 10px 0">实名认证</h1>
        <el-form-item label="真实姓名" prop="realname">
          <el-input
            v-model="form.realname"
            :disabled="userInfo.is_auth === 3"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="id_card_no">
          <el-input
            v-model="form.id_card_no"
            :disabled="userInfo.is_auth === 3"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证正面" prop="id_card_front">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action
            :http-request="Upload"
            :on-success="handleSuccessFront"
            :on-error="handfileErrFrout"
            :before-upload="beforeAvatarUpload"
            v-model="form.id_card_front"
            :disabled="userInfo.is_auth === 3"
          >
            <img
              v-if="form.id_card_front"
              :src="form.id_card_front"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="身份证反面" prop="id_card_back">
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :http-request="Upload"
            :on-success="handleAvatarSuccess"
            :on-error="handfileErrBack"
            :before-upload="beforeAvatarUpload"
            v-model="form.id_card_back"
            :disabled="userInfo.is_auth === 3"
          >
            <img
              v-if="form.id_card_back"
              :src="form.id_card_back"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-button
          style="width: 400px; height: 50px"
          type="success"
          @click="submitForm"
          :disabled="userInfo.is_auth === 3"
          >{{
            userInfo.is_auth === 2 || userInfo.is_auth === 1
              ? "修改信息"
              : "提交审核"
          }}</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import { client, getFileNameUUID, dateFormat } from "@/util/update";
let checkAge = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("身份证号码不能为空"));
  }
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  let flag = reg.test(value);
  if (flag) {
    callback();
  } else {
    callback(new Error("请输入正确的身份证号码"));
  }
};
let checkName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("姓名不能为空"));
  }
  let reg = /^[\u4e00-\u9fa5 ]{2,20}$/;
  let flag = reg.test(value);
  if (flag) {
    callback();
  } else {
    callback(new Error("请输入正确的姓名"));
  }
};
export default {
  data() {
    return {
      userInfo: {
        nickname: "",
        avatar: "",
      },
      changeFlag: false,
      imageUrl: "",
      input: "",
      fileList: [], //文件列
      showProgress: false, //进度条的显示
      dataObj: {}, //存签名信息
      progress: 0, //进度条数据
      form: {
        realname: "",
        id_card_no: "",
        id_card_front: "",
        id_card_back: "",
      },
      rules: {
        realname: [
          { required: true, message: "请输入姓名", trigger: "change" },
          {
            validator: checkName,
            trigger: "blur",
          },
        ],
        id_card_no: [
          { required: true, message: "请输入身份证号码", trigger: "change" },
          { validator: checkAge, trigger: "blur" },
        ],
        id_card_front: [
          {
            required: true,
            message: "请选择身份证正面照片",
            trigger: "change",
          },
        ],
        id_card_back: [
          {
            required: true,
            message: "请选择身份证反面照片",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getInfo();
    this.getOssData();
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 30;

      if (!isJPG) {
        this.$message.error("请选择正确的图片类型");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过30MB!");
      }
      return isJPG && isLt2M;
    },
    handleSuccess(res, file, fileList) {
      this.imageUrl = res.url;
    },
    handleSuccessFront(res, file, fileList) {
      this.form.id_card_front = res.url;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.form.id_card_back = res.url;
    },
    handfileErr(err, file, fileList) {
      this.imageUrl = this.userInfo.avatar;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.form.id_card_back = res.url;
    },
    async Upload(option) {
      const file = option.file;
      const that = this;
      const date = dateFormat(new Date(), "yyyyMMdd"); // 当前时间
      const dateTime = dateFormat(new Date(), "yyyyMMddhhmmss"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const extensionName = file.name.substr(file.name.indexOf(".")); // 文件扩展名
      const fileName =
        "image/" + date + "/" + dateTime + "_" + randomStr + extensionName;
      // 执行上传
      let dataObj = {
        accessKeyId: that.dataObj.AccessKeyId,
        secure: true,
        accessKeySecret: that.dataObj.AccessKeySecret,
        bucket: that.dataObj.bucket,
        region: that.dataObj.Region,
        stsToken: that.dataObj.SecurityToken,
      };
      try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        const result = await client(dataObj).put(fileName, file); // Message.success("上传成功");

        return result;
      } catch (e) {
        // Message.error("上传失败请重试");
        return e;
      }
    },
    randomString(num) {
      const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let res = "";
      for (let i = 0; i < num; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
      }
      return res;
    },
    async getInfo() {
      const [err, res] = await this.$http.post("web/User/getInfo", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        this.$router.push("/");
        this.$store.commit("CHANGE_LOGIN_TOKEN", "");
        this.$store.commit("CHANGE_NICK_NAME", "");
        this.$store.commit("CHANGE_USER_ID", "");
        localStorage.setItem("stateInfo", JSON.stringify(this.$store.state));
        return;
      }
      this.userInfo = res;
      /* if (res.is_auth !== 0 && res.is_auth !== 2) { */
        this.getCard();
      /* } */
      if (res.is_auth === 2) {
        this.$alert(
          "您的实名认证失败，请仔细核对身份信息，并重新提交申请",
          "审核进度",
          {
            confirmButtonText: "确定",
          }
        );
      }
    },
    async changeUserInfo() {
      if (!this.changeFlag) {
        this.imageUrl = this.userInfo.avatar;
        this.input = this.userInfo.nickname;
      } else {
        const [err, res] = await this.$http.post("web/User/update", {
          id: this.$store.state.user_id,
          token: this.$store.state.login_token,
          nickname: this.input,
          avatar: this.imageUrl,
        });
        if (err) {
          return;
        }
        this.$store.commit("CHANGE_NICK_NAME", this.input);
        this.$store.commit("CHANGE_AVATAR", this.imageUrl);
        this.getInfo();
      }
      this.changeFlag = !this.changeFlag;
    },
    async getOssData() {
      const [err, res] = await this.$http.post("web/User/getSts", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        return;
      }
      this.dataObj = res;
    },
    async getCard() {
      const [err, res] = await this.$http.post("web/user/getCard", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        return;
      }
      const { realname, id_card_no, id_card_front, id_card_back } = res;
      this.form.realname = realname;
      this.form.id_card_no = id_card_no;
      this.form.id_card_front = id_card_front;
      this.form.id_card_back = id_card_back;
    },
    handfileErrFrout(err, file, fileList) {
      this.form.id_card_front = "";
    },
    handfileErrBack(err, file, fileList) {
      this.form.id_card_back = "";
    },
    async submitForm() {
      this.$refs.form.validate(async (v, i) => {
        if (v) {
          const [err, res] = await this.$http.post("web/user/idCard", {
            id: this.$store.state.user_id,
            token: this.$store.state.login_token,
            ...this.form,
          });
          if (err) {
            return;
          }
          this.$router.push("/");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  color: #fff;
  display: flex;
  justify-content: space-around;
  .user_info {
    width: 500px;
    height: 650px;
    text-align: center;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;
    color: #000;
    div {
      padding: 5px 0;
    }
    .title {
      text-align: right;
    }
  }
  .shiming {
    width: 500px;
    height: 650px;

    background-color: #fff;
    padding: 20px;
    margin: 20px 0;
    overflow: auto;
  }
  /deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 400px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 400px;
    height: 178px;
    display: block;
  }
  /deep/.user_info .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.user_info .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/.user_info .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.user_info .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>